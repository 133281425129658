import React from "react";
import _ from "lodash";

function PackageHeader({ tour }) {
  if (_.isEmpty(tour)) return <></>;

  return (
    <>
      <div className="pd-header">
        <div className=" pd-top row row-cols-lg-4 row-cols-md-2 row-cols-2 gy-4">
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/pd1.svg"}
                  alt="PackageIMG"
                />
              </div>
              <div className="info">
                <h6>Thời gian</h6>
                <span>{tour.duration} ngày</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/pd2.svg"}
                  alt="PackageIMG"
                />
              </div>
              <div className="info">
                <h6>Chuyến đi</h6>
                <span style={{ textTransform: "capitalize" }}>
                  {tour.season}
                </span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/pd3.svg"}
                  alt="PackageIMG"
                />
              </div>
              <div className="info">
                <h6>Thành viên</h6>
                <span>{tour.group_size} người</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="pd-single-info">
              <div className="info-icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/pd4.svg"}
                  alt="PackageIMG"
                />
              </div>
              <div className="info">
                <h6>Hướng dẫn viên</h6>
                <span>{tour.guide_size} người</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pd-thumb">
          <img
            src={
              tour.background.data?.attributes?.url ||
              process.env.PUBLIC_URL + "/images/package/best-s1.png"
            }
            height={428}
            style={{ objectFit: "cover" }}
            alt="PackageIMG"
          />
        </div>
        <div className="header-bottom">
          <div className="pd-lavel d-flex justify-content-between align-items-center flex-wrap gap-2">
            <h5 className="location">
              <i className="bi bi-geo-alt" /> {tour.location}
            </h5>
            <ul className="d-flex align-items-center rating">
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
              <li>
                <i className="bi bi-star-fill" />
              </li>
            </ul>
          </div>
          <h2 className="pd-title">{tour.name}</h2>
        </div>
      </div>
    </>
  );
}

export default PackageHeader;
