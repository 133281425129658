import React, { useEffect, useState } from "react";
import Pagination from "../../common/Pagination";
import { GET_TOURS } from "../../../api-client";
import PackageCard from "./PackageCard";

function PackageGridWrapper() {
  const [tours, setTours] = useState([]);
  const local = window.localStorage.getItem("search");
  const id = local ? JSON.parse(local) : null;
  const [meta, setMeta] = useState({
    pageSize: 12,
    page: 1,
    pageCount: 0,
    total: 0,
  });

  const getTours = () => {
    let object = "";
    if (id?.idLocation) {
      object += `filters[location_tour][id][$containsi][0]=${id?.idLocation}&`;
    }
    if (id?.idEvent) {
      object += `filters[event][id][$containsi][1]=${id?.idEvent}&`;
    }
    if (id?.idSeason) {
      object += `filters[season][$containsi][2]=${id?.idSeason}&`;
    }

    GET_TOURS(object, meta)
      .then(({ data, status }) => {
        if (status === 200) {
          setTours(data.data);
          setMeta(data.meta.pagination);
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTours();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id?.idLocation, id?.idEvent, id?.idSeason, meta.page]);

  return (
    <>
      {/* ===============  Package gird area start =============== */}
      <div className="package-wrapper pt-110">
        <div className="container">
          <div className="row g-4">
            {tours.map((item, idx) => (
              <div className="col-lg-4 col-md-6" key={idx}>
                <PackageCard tour={item} />
              </div>
            ))}
          </div>
          {tours.length > 0 ? (
            <Pagination meta={meta} setMeta={setMeta} />
          ) : null}
        </div>
      </div>
      {/* ===============  Package gird area end =============== */}
    </>
  );
}

export default PackageGridWrapper;
