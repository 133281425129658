import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import GallaryWrapper from "./GallaryWrapper";

function GallaryPage() {
  return (
    <>
      <Breadcrumb name="Thư viện" />
      <GallaryWrapper />
    </>
  );
}

export default GallaryPage;
