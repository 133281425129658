import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import WidgetForm from "./WidgetForm";
import WidgetPackageOffer from "./WidgetPackageOffer";
import WidgetPackageTag from "./WidgetPackageTag";
import PackageHeader from "./PackageHeader";
import PackageDetailsTab from "./PackageDetailsTab";
import { GET_REVIEWS_BY_PACKAGE, READ_TOUR } from "../../../api-client";

function PackageDetailsWrap() {
  const { id } = useParams();
  const [tour, setTour] = useState(null);
  const [reviews, setReviews] = useState([]);

  const readTour = () => {
    READ_TOUR(id)
      .then(({ data, status }) => {
        if (status === 200) setTour(data.data.attributes);
      })
      .catch((err) => console.log(err));
  };

  const getReviews = () => {
    GET_REVIEWS_BY_PACKAGE(id)
      .then(({ data, status }) => {
        if (status === 200) {
          setReviews(data.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    readTour();
    getReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (_.isEmpty(tour)) return <></>;

  return (
    <>
      <div className="package-details-wrapper pt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="tour-package-details">
                <PackageHeader tour={tour} />
                <PackageDetailsTab
                  tour={tour}
                  id={id}
                  reviews={reviews || []}
                  callback={getReviews}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="package-sidebar">
                <WidgetForm tour={tour} id={id} />
                <WidgetPackageOffer />
                <WidgetPackageTag />
                <aside className="widget-banner mt-30">
                  <Link to={"#"}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/banner/sidebar-banner.png"
                      }
                      alt="PackageIMG"
                      className="img-fluid"
                    />
                  </Link>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageDetailsWrap;
