import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { READ_TAGS } from "../../../api-client";
import Breadcrumb from "../../common/Breadcrumb";
import PackageGridWrapper from "./PackageGridWrapper";

function PackageTag() {
  const params = useParams();
  const [tag, setTag] = useState([]);

  const getPackageByTags = () => {
    READ_TAGS(params.id)
      .then(({ data }) => {
        setTag(data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getPackageByTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (_.isEmpty(tag)) return <></>;

  return (
    <>
      <Breadcrumb name={`Tour ${tag.attributes.tag_name}`} />
      <PackageGridWrapper tours={tag.attributes.package_tours.data || []} />
    </>
  );
}

export default PackageTag;
