import React from "react";
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_REVIEW } from "../../../api-client";

const PackageReviewForm = ({ id, callback }) => {
  const { register, handleSubmit, reset } = useForm();

  const notify = (mess) =>
    toast.error(mess || "Bạn cần điền đầy đủ thông tin!");
  const onHandleSubmit = (values) => {
    const data = {
      ...values,
      package_tour: id,
    };
    POST_REVIEW(data)
      .then(() => {
        toast.success("Nhận xét thành công!");
        reset();
        callback();
      })
      .catch(() => {
        toast.error("Không thực hiện được chức năng!");
      });
  };
  const onHandleErrors = () => {
    notify();
  };

  return (
    <>
      <ToastContainer />
      <form
        onSubmit={handleSubmit(onHandleSubmit, onHandleErrors)}
        id="comment_form"
        method="post"
      >
        <div className="comment-form" style={{ marginBottom: 30 }}>
          <h4>Hãy để lại bình luận của bạn</h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <input
                  type="text"
                  placeholder="Họ tên"
                  id="name"
                  {...register("name", { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group">
                <input
                  type="text"
                  placeholder="Email"
                  id="mail"
                  {...register("mail", { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <select
                  placeholder="Điểm"
                  id="rate"
                  {...register("rate", { required: true })}
                >
                  <option value="five">5 sao</option>
                  <option value="four">4 sao</option>
                  <option value="three">3 sao</option>
                  <option value="two">2 sao</option>
                  <option value="one">1 sao</option>
                </select>
              </div>
            </div>
          </div>
          <div className="custom-input-group">
            <textarea
              cols={20}
              rows={7}
              {...register("content", { required: true })}
              placeholder="Nhận xét"
              defaultValue={""}
            />
          </div>

          <div className="custom-input-group">
            <div className="submite-btn">
              <button type="submit">Gửi nhận xét</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PackageReviewForm;
