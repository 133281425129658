import React, { useState, useEffect } from "react";
import { GET_TOUR_GUIDE } from "../../../api-client";
import GuideCardGama from "../../common/GuideCardGama";

function GuideWrap() {
  const [guide, setGuide] = useState([]);

  const getGuide = () => {
    GET_TOUR_GUIDE()
      .then(({ data, status }) => {
        if (status === 200) {
          setGuide(data.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getGuide();
  }, []);

  return (
    <>
      <div className="guide-wrapper pt-110">
        <div className="container">
          <div className="row g-4">
            {guide.length > 0
              ? guide.map((item, idx) => (
                  <div className="col-lg-4 col-md-6" key={idx}>
                    <GuideCardGama guide={item.attributes} />
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default GuideWrap;
