import React, { useState, useReducer, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {
  GET_DESTINATIONS,
  GET_EVENTS,
  GET_LOCATION,
  GET_TAGS,
} from "../../api-client";

function Header() {
  const [isSearchActive, setSearchActive] = useState(false);
  const [isOpenActive, setOpenActive] = useState(false);

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname !== "/package-search") {
      window.localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname !== "/package-search"]);

  const widthScreen = window.screen.width;

  /*using reducer to manage the active or inactive menu*/

  useEffect(() => {
    // Add scroll event when the component is loaded
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector("header");
    const scrollTop = window.scrollY;
    scrollTop >= 200
      ? header?.classList.add("sticky")
      : header?.classList.remove("sticky");
  };

  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case "home":
        return { activeMenu: "home" };
      case "in":
        return { activeMenu: "in" };
      case "out":
        return { activeMenu: "out" };
      case "more":
        return { activeMenu: "more" };
      case "destination":
        return { activeMenu: "destination" };
      default:
        return { activeMenu: "" };
    }
  }

  const handleSearchBtn = () => {
    if (isSearchActive === false || isSearchActive === 0) {
      setSearchActive(1);
    } else {
      setSearchActive(false);
    }
  };
  const handleOpenBtn = () => {
    if (isOpenActive === false || isOpenActive === 0) {
      setOpenActive(1);
    } else {
      setOpenActive(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [events, setEvents] = useState([]);
  const getEvents = () => {
    GET_EVENTS()
      .then(({ data, status }) => {
        if (status === 200) setEvents(data.data);
      })
      .catch((err) => console.log(err));
  };

  const [dest, setDest] = useState([]);
  const getDestination = () => {
    GET_DESTINATIONS()
      .then(({ data }) => {
        setDest(data.data);
      })
      .catch((err) => console.log(err));
  };

  const [location, setLocation] = useState([]);
  const getLocations = () => {
    GET_LOCATION()
      .then(({ data, status }) => {
        if (status === 200) {
          setLocation(data.data);
        }
      })
      .catch((error) => console.log(error));
  };

  const [tags, setTags] = useState([]);
  const getTags = () => {
    GET_TAGS()
      .then(({ data, status }) => {
        if (status === 200) {
          setTags(data.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getDestination();
    getTags();
    getEvents();
    getLocations();
  }, []);

  const [search, setSearch] = useState(() => {
    const localSearch = window.localStorage.getItem("search");
    const search = localSearch
      ? JSON.parse(localSearch)
      : { idSeason: "", idLocation: "", idEvent: "" };
    return search;
  });

  const handleChangeLocation = (event) => {
    setSearch((search) => ({ ...search, idLocation: event.value }));
    window.localStorage.setItem(
      "search",
      JSON.stringify({ ...search, idLocation: event.value })
    );
  };

  const handleChangeEvent = (event) => {
    setSearch((search) => ({ ...search, idEvent: event.value }));
    window.localStorage.setItem(
      "search",
      JSON.stringify({ ...search, idEvent: event.value })
    );
  };

  const handleChangeSeason = (event) => {
    setSearch((search) => ({ ...search, idSeason: event.value }));
    window.localStorage.setItem(
      "search",
      JSON.stringify({ ...search, idSeason: event.value })
    );
  };

  const optionsForDestination = location.map((item) => ({
    value: item.id,
    label: item.attributes.name,
  }));
  const optionsForTravel = events.map((item) => ({
    value: item.id,
    label: item.attributes.event_name,
  }));
  const optionsForPerson = [
    { value: "Mùa Xuân", label: "Mùa Xuân" },
    { value: "Mùa Hạ", label: "Mùa Hạ" },
    { value: "Mùa Thu", label: "Mùa Thu" },
    { value: "Mùa Đông", label: "Mùa Đông" },
    { value: "Bốn Mùa", label: "Bốn Mùa" },
  ];

  return (
    <>
      <div
        className={`${"main-searchbar-wrapper"} ${
          isSearchActive === 1 ? "search-active" : ""
        }`}
      >
        <div className="container">
          <div className="multi-main-searchber">
            <div
              onClick={handleSearchBtn}
              className="main-searchbar-close search-toggle"
            >
              <i className="bi bi-x-lg" />
            </div>
            <form onSubmit={(e) => e.preventDefault()} id="main_searchbar">
              <div className="row g-4">
                <div className="col-lg-10">
                  <div className="row gx-0 gy-4">
                    <div className="col-lg-4 col-md-6">
                      <div className="select-box">
                        <div className="searchbox-icons">
                          <i className="bi bi-geo-alt" />
                        </div>
                        <div className="searchbox-input">
                          <label htmlFor="activity-dropdown">Địa điểm</label>
                          <Select
                            placeholder="Bạn muốn đến đâu?"
                            className="selectbox"
                            onChange={handleChangeLocation}
                            options={optionsForDestination}
                            touchUi={false}
                            showGroupWheel={true}
                          ></Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="select-box">
                        <div className="searchbox-icons">
                          <i className="bi bi-text-paragraph" />
                        </div>
                        <div className="searchbox-input">
                          <label htmlFor="activity-dropdown">Sự kiện</label>
                          <Select
                            className="selectbox"
                            placeholder="Tất cả sự kiện"
                            onChange={handleChangeEvent}
                            options={optionsForTravel}
                          ></Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="select-box">
                        <div className="searchbox-icons">
                          <i className="bi bi-capslock" />
                        </div>
                        <div className="searchbox-input">
                          <label htmlFor="activity-dropdown">Mùa</label>
                          <Select
                            className="selectbox"
                            onChange={handleChangeSeason}
                            placeholder="Quanh năm"
                            options={optionsForPerson}
                          ></Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="main-form-submit">
                    <Link
                      to={`${process.env.PUBLIC_URL}/package-search`}
                      onClick={scrollTop}
                    >
                      <button
                        type="submit"
                        onClick={() => {
                          setSearchActive(false);
                        }}
                      >
                         Tìm kiếm
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="topbar-area topbar-style-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 align-items-center d-xl-flex d-none">
              <div className="topbar-contact-left">
                <ul className="contact-list">
                  <li>
                    <i className="bi bi-telephone-fill" />
                    <a href="tel:+84 98 791 66 17"> +84 98 791 66 17</a>
                  </li>
                  <li>
                    <i className="bi bi-envelope-fill" />
                    <a href="mailto:dhavntravel@gmail.com">
                      dhavntravel@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6 text-xl-center text-md-start text-center">
              <div className="topbar-ad">
                <Link
                  onClick={scrollTop}
                  to={`${process.env.PUBLIC_URL}/package`}
                >
                  DHA Education & Travel Việt Nam
                </Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 d-md-flex  d-none align-items-center justify-content-end">
              <ul className="topbar-social-links">
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100086202676835"
                  >
                    <i className="bx bxl-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/"
                  >
                    <i className="bx bxl-instagram-alt" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <header>
        <div className="header-area header-style-one">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                <div className="logo d-flex justify-content-between align-items-center">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/home`}
                  >
                    {widthScreen > 425 ? (
                      <img
                        src={process.env.PUBLIC_URL + "/images/logo-v3-w.jpg"}
                        height={70}
                        alt="logo"
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + "/images/logo-v3-w.jpg"}
                        height={52}
                        alt="logo"
                      />
                    )}
                  </Link>
                  <div className="d-flex align-items-center gap-4">
                    <div className="nav-right d-xl-none">
                      <ul className="nav-actions">
                        {/* <li
                          onClick={handleCategoryBtn}
                          className="category-toggle"
                        >
                          <i className="bx bx-category" />
                        </li> */}
                        <li className="search-toggle" onClick={handleSearchBtn}>
                          <i className="bx bx-search-alt" />
                        </li>
                      </ul>
                    </div>
                    <div
                      className="mobile-menu d-flex "
                      onClick={handleOpenBtn}
                    >
                      <Link
                        to={"#"}
                        className={
                          isOpenActive
                            ? "hamburger d-block d-xl-none h-active"
                            : "hamburger d-block d-xl-none"
                        }
                      >
                        <span className="h-top" />
                        <span className="h-middle" />
                        <span className="h-bottom" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-6">
                <nav
                  className={
                    isOpenActive
                      ? "main-nav float-end slidenav"
                      : "main-nav float-end"
                  }
                >
                  <div className="logo d-xl-none text-center">
                    <Link to={"#"}>
                      <img
                        width={"78%"}
                        src={process.env.PUBLIC_URL + "/images/logo-v3-w.jpg"}
                        alt="catagoryIMG"
                      />
                    </Link>
                  </div>
                  <ul>
                    <li>
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/home`}
                      >
                        Trang chủ
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/about`}
                      >
                        Giới thiệu
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/package`}
                      >
                        Gói du lịch
                      </NavLink>
                    </li>

                    <li
                      className="has-child-menu"
                      onClick={() => dispatch({ type: "in" })}
                    >
                      <Link to={"#"}>Trong nước</Link>
                      <i className="fl flaticon-plus">+</i>
                      <ul
                        className={
                          state.activeMenu === "in"
                            ? "sub-menu d-block"
                            : "sub-menu d-none"
                        }
                      >
                        {dest.length > 0
                          ? dest
                              .filter((item) => item.attributes.is_domestic)
                              .map((item, idx) => (
                                <li key={idx}>
                                  <NavLink
                                    onClick={scrollTop}
                                    to={`${process.env.PUBLIC_URL}/destination/${item.id}/in`}
                                  >
                                    {item.attributes.name}
                                  </NavLink>
                                </li>
                              ))
                          : null}
                      </ul>
                    </li>

                    <li
                      className="has-child-menu"
                      onClick={() => dispatch({ type: "out" })}
                    >
                      <Link to={"#"}>Nước ngoài</Link>
                      <i className="fl flaticon-plus">+</i>
                      <ul
                        className={
                          state.activeMenu === "out"
                            ? "sub-menu d-block"
                            : "sub-menu d-none"
                        }
                      >
                        {dest.length > 0
                          ? dest
                              .filter((item) => !item.attributes.is_domestic)
                              .map((item, idx) => (
                                <li key={idx}>
                                  <NavLink
                                    onClick={scrollTop}
                                    to={`${process.env.PUBLIC_URL}/destination/${item.id}/out`}
                                  >
                                    {item.attributes.name}
                                  </NavLink>
                                </li>
                              ))
                          : null}
                      </ul>
                    </li>

                    <li
                      className="has-child-menu"
                      onClick={() => dispatch({ type: "more" })}
                    >
                      <Link to={"#"}>Thêm</Link>
                      <i className="fl flaticon-plus">+</i>
                      <ul
                        className={
                          state.activeMenu === "more"
                            ? "sub-menu d-block"
                            : "sub-menu d-none"
                        }
                      >
                        <li>
                          <NavLink
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/guide`}
                          >
                            Hướng dẫn viên
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/gallary`}
                          >
                            Thư viện Ảnh
                          </NavLink>
                        </li>
                        {tags.length > 0
                          ? tags.map((item, idx) => (
                              <li key={idx}>
                                <NavLink
                                  onClick={scrollTop}
                                  to={`${process.env.PUBLIC_URL}/blogs/${item.id}`}
                                >
                                  {item.attributes.tag_name}
                                </NavLink>
                              </li>
                            ))
                          : null}
                        {/* <li>
                          <NavLink
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/faq`}
                          >
                            FAQ
                          </NavLink>
                        </li> */}
                        {/* <li>
                          <NavLink
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/error`}
                          >
                            404
                          </NavLink>
                        </li> */}
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/contact`}
                      >
                        Liên hệ
                      </NavLink>
                    </li>
                  </ul>
                  <div className="inner-contact-options d-xl-none">
                    <div className="contact-box-inner">
                      <i className="bi bi-telephone-fill" />
                      <Link to="tel:+84 98 791 66 17"> +84 98 791 66 17</Link>
                    </div>
                    <div className="contact-box-inner">
                      <i className="bi bi-envelope-fill" />
                      <Link to="mailto:dhavntravel@gmail.com">
                        dhavntravel@gmail.com
                      </Link>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-xl-2">
                <div className="nav-right float-end d-xl-flex d-none ">
                  <ul className="nav-actions">
                    {/* <li className="category-toggle" onClick={handleCategoryBtn}>
                      <i className="bx bx-category" />
                    </li> */}
                    <li className="search-toggle" onClick={handleSearchBtn}>
                      <i className="bx bx-search-alt" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* ===============  header area end =============== */}
    </>
  );
}

export default Header;
