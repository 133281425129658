import React from "react";
import { Link } from "react-router-dom";

function ContactWrapperArea() {
  return (
    <>
      <div className="contact-wrapper pt-110">
        <div className="container">
          <div className="row align-items-center justify-content-lg-center gy-5">
            <div className="col-lg-6">
              <div className="contatc-intro-figure">
                <img
                  src={process.env.PUBLIC_URL + "/images/banner/contact-bg.png"}
                  alt="ContactBannerIMG"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-info">
                <h3>Thông tin liên hệ.</h3>
                <ul>
                  <li>
                    <h6 style={{ marginBottom: 8 }}>
                      CÔNG TY TNHH GIÁO DỤC VÀ DU LỊCH DHA VIỆT NAM
                    </h6>
                    <h6>DHA VIET NAM EDUCATION AND TOURISM COMPANY LIMITED</h6>
                  </li>
                  <li>
                    <h6>Liên hệ.</h6>

                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="tel:+84 98 791 66 17"
                    >
                      +84 98 791 66 17
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="tel:+84 77 836 63 88"
                    >
                      +84 77 836 63 88
                    </a>
                    <br />
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="tel:+84 96 583 98 48"
                    >
                      +84 96 583 98 48
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="tel:+84 35 241 05 27"
                    >
                      +84 35 241 05 27
                    </a>
                  </li>
                  <li>
                    <h6>Địa điểm.</h6>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://goo.gl/maps/cTvpy9Lq5a7xj7Kd9"
                    >
                      Số 2, 87/43 Yên Lộ, Yên Nghĩa, Hà Đông, Hanoi, Vietnam
                    </a>
                  </li>
                  <li>
                    <h6>Xem thêm về chúng tôi.</h6>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="mailto:dhavntravel@gmail.com"
                    >
                      Email: dhavntravel@gmail.com
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=100086202676835"
                    >
                      Facebook: https://www.facebook.com/dhavntravel
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="contact-map mt-120">
            <div className="mapouter">
              <div className="gmap_canvas">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3725.882561901587!2d105.73101131540592!3d20.957230795591148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zU-G7kSAyLCBOZ8OhY2ggODcvNDMsIFBo4buRIFnDqm4gTOG7mSAsIFBoxrDhu51uZyBZw6puIE5naMSpYSwgUXXhuq1uIEjDoCDEkMO0bmcsIFRow6BuaCBwaOG7kSBIw6AgTuG7mWksIFZp4buHdCBOYW0!5e0!3m2!1svi!2shk!4v1668094508583!5m2!1svi!2shk"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />

                <Link to={"https://123movies-to.org"} />
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-110">
          <form onSubmit={(e) => e.preventDefault()} id="contact_form">
            <div className="contact-form-wrap">
              <h4>Gửi mail để nhận thêm thông báo du lịch của chúng tôi</h4>
              <p>Chúng tôi sẽ không để lộ bất kỳ thông tin nào của bạn</p>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">Họ và tên</label>
                    <input
                      required
                      type="text"
                      placeholder="Tên của bạn"
                      id="name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="email">Email</label>
                    <input
                      required
                      type="text"
                      placeholder="Email của bạn"
                      id="email"
                    />
                  </div>
                </div>
              </div>
              <div className="custom-input-group">
                <textarea
                  cols={20}
                  rows={7}
                  required
                  placeholder="Vài lời nhận xét"
                  defaultValue={""}
                />
              </div>
              <div className="custom-input-group">
                <div className="submite-btn">
                  <button type="submit">Gửi thông điệp</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactWrapperArea;
