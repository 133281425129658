import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import ModalVideo from "react-modal-video";
import { monthFormat } from "../../functions";
import PackageReviewForm from "./PackageReviewForm";
import _ from "lodash";

const PackageDetailsTab = ({ tour, id, reviews, callback }) => {
  const [isOpen, setOpen] = useState(false);
  // console.log(tour);

  if (_.isElement(tour)) return <></>;

  return (
    <>
      <div className="package-details-tabs">
        <ul
          className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-package1"
              data-bs-toggle="pill"
              data-bs-target="#pill-body1"
              type="button"
              role="tab"
              aria-controls="pill-body1"
              aria-selected="true"
            >
              <i className="bi bi-info-lg" /> Thông tin chi tiết
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-package2"
              data-bs-toggle="pill"
              data-bs-target="#pill-body2"
              type="button"
              role="tab"
              aria-controls="pill-body2"
              aria-selected="false"
            >
              <i className="bi bi-file-earmark-spreadsheet" />
              Kế hoạch
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-package3"
              data-bs-toggle="pill"
              data-bs-target="#pill-body3"
              type="button"
              role="tab"
              aria-controls="pill-body3"
              aria-selected="false"
            >
              <i className="bi bi-images" /> Hình ảnh
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-package4"
              data-bs-toggle="pill"
              data-bs-target="#pill-body4"
              type="button"
              role="tab"
              aria-controls="pill-body4"
              aria-selected="false"
            >
              <i className="bi bi-geo-alt" /> Địa điểm
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {/* package info tab */}
          <div
            className="tab-pane fade show active package-info-tab mt-5"
            id="pill-body1"
            role="tabpanel"
            aria-labelledby="pills-package1"
          >
            <h3 className="d-subtitle">Chi tiết về chuyến đi</h3>
            <div dangerouslySetInnerHTML={{ __html: tour?.details }}></div>
            <div className="p-info-featured-img row position-relative g-3  row-cols-1 row-cols-sm-2">
              <div className="col">
                <div className="featured-img">
                  <img
                    src={
                      tour.media.data.length > 0
                        ? tour.media.data[0].attributes.url
                        : process.env.PUBLIC_URL + "/images/package/best-s1.png"
                    }
                    alt="PackageIMG"
                    height={412}
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="featured-img">
                  <img
                    src={
                      tour.media.data.length > 1
                        ? tour.media.data[1].attributes.url
                        : process.env.PUBLIC_URL + "/images/package/best-s1.png"
                    }
                    alt="PackageIMG"
                    style={{ objectFit: "cover" }}
                    height={412}
                  />
                </div>
              </div>
              <div className="featured-video position-absolute ">
                <img
                  src={
                    tour.media.data.length > 2
                      ? tour.media.data[2].attributes.url
                      : process.env.PUBLIC_URL + "/images/package/best-s1.png"
                  }
                  height={120}
                  alt="PackageIMG"
                />
                <div className="video-overlay">
                  <div className="play-icon video-popup">
                    {/* <i className="bi bi-play-fill" /> */}
                    <i
                      onClick={() => setOpen(true)}
                      className="bi bi-play-fill"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <table className="table package-info-table mb-0">
              <tbody>
                <tr>
                  <th>Điểm đến</th>
                  <td>{tour.location_tour.data.attributes.name}</td>
                </tr>
                {/* <tr>
                  <th>Depature</th>
                  <td>Yes Required</td>
                </tr> */}
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Thời gian khởi hành</th>
                  <td>{tour.season}</td>
                </tr>
                <tr>
                  <th>Tiện ích kèm theo</th>
                  <td>
                    <ul className="included-list">
                      {tour.include.length > 0
                        ? tour.include.map((item, idx) => (
                            <li key={idx}>
                              <i className="bi bi-check2" />
                              {item.content}
                            </li>
                          ))
                        : null}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Cần lưu ý</th>
                  <td>
                    <ul className="excluded-list">
                      {tour.exclude.length > 0
                        ? tour.exclude.map((item, idx) => (
                            <li key={idx}>
                              <i className="bi bi-x-lg" />
                              {item.content}
                            </li>
                          ))
                        : null}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="tour-transport-col">
                    <div className="tour-transport  d-flex align-items-center justify-content-center">
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/bus.svg"}
                        alt="PackageIMG"
                      />
                      <span>
                        Phương tiện di chuyển:{" "}
                        {tour.transports.data
                          ?.map((item) => item?.attributes?.trans_name)
                          .join(", ")}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="rating-overview">
              <h3 className="d-subtitle">Đánh giá tổng quan</h3>
              <div className="rating-overview-row row g-0">
                <div className="col-lg-4 col-md-5 col-sm-5">
                  <div className="total-rating d-flex justify-content-center align-items-center flex-column h-100 ">
                    <h3>{reviews.length || 0}</h3>
                    <h5>Tuyệt vời</h5>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-7">
                  <div className="rating-info">
                    <div className="rating-box">
                      <h6>
                        Điểm đến <span>5.0</span>
                      </h6>
                      <div className="rating-bar" />
                    </div>
                    <div className="rating-box">
                      <h6>
                        Phương tiện <span>5.0</span>
                      </h6>
                      <div className="rating-bar" />
                    </div>
                    <div className="rating-box">
                      <h6>
                        Hài lòng <span>5.0</span>
                      </h6>
                      <div className="rating-bar" />
                    </div>
                    <div className="rating-box">
                      <h6>
                        Khách sạn <span>5.0</span>
                      </h6>
                      <div className="rating-bar" />
                    </div>
                    <div className="rating-box">
                      <h6>
                        Thức ăn <span>5.0</span>
                      </h6>
                      <div className="rating-bar" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comment-section">
              <ul className="comment-list">
                {reviews.length > 0
                  ? reviews
                      .filter((item) => !item.attributes.hidden)
                      .map((item, idx) => (
                        <li
                          className="single-comment d-flex flex-sm-row flex-column "
                          key={idx}
                        >
                          <div className="commmentor">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/images/reviewer/avatar_0${
                                  ((idx + 5) % 5) + 1
                                }.webp`
                              }
                              alt="PackageIMG"
                            />
                          </div>
                          <div
                            className="comment mt-4 mt-sm-0"
                            style={{ width: "100%" }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="info">
                                <h6>{item.attributes.name}</h6>
                                <span>
                                  {monthFormat(item.attributes.createdAt)}
                                </span>
                              </div>
                              <ul className="rating d-flex ">
                                <li>
                                  <i className="bi bi-star-fill" />
                                </li>
                                <li>
                                  <i className="bi bi-star-fill" />
                                </li>
                                <li>
                                  <i className="bi bi-star-fill" />
                                </li>
                                <li>
                                  <i className="bi bi-star-fill" />
                                </li>
                                <li>
                                  <i className="bi bi-star-fill" />
                                </li>
                              </ul>
                            </div>
                            <p style={{ width: "100%" }}>
                              {item.attributes.content}
                            </p>
                          </div>
                        </li>
                      ))
                  : null}
              </ul>
            </div>

            <PackageReviewForm id={id} callback={callback} />
          </div>
          {/* package plans tab */}
          <div
            className="tab-pane fade package-plan-tab tab-body"
            id="pill-body2"
            role="tabpanel"
            aria-labelledby="pills-package2"
          >
            <h3 className="d-subtitle">Chi tiết</h3>
            <div dangerouslySetInnerHTML={{ __html: tour.details }}></div>
            <div className="accordion plans-accordion" id="planAccordion">
              {tour.dailies.data.length > 0
                ? tour.dailies.data.map((item, idx) => (
                    <div
                      className="accordion-item plans-accordion-single"
                      key={idx}
                    >
                      <div
                        className="accordion-header"
                        id={`planHeading${idx}`}
                      >
                        <div
                          className="accordion-button"
                          data-bs-toggle="collapse"
                          data-bs-target="#planCollapse1"
                          role="navigation"
                        >
                          <div className="paln-index-circle">
                            <h4>{idx + 1}</h4>
                          </div>
                          <div className="plan-title">
                            <h5>
                              Ngày {idx + 1} : {item.attributes.title}
                            </h5>
                            <h6>
                              {item.attributes.start_time?.substring(0, 5)} đến{" "}
                              {item.attributes.end_time?.substring(0, 5)}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div
                        id="planCollapse1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="planHeadingOne"
                        data-bs-parent="#planAccordion"
                      >
                        <div className="accordion-body plan-info">
                          <p>{item.attributes.description}</p>
                          <div>
                            <ul className="included-list">
                              {item.attributes.task.length > 0
                                ? item.attributes.task.map((item, idx) => (
                                    <li key={idx}>
                                      <i className="bi bi-check2" />
                                      {item.content}
                                    </li>
                                  ))
                                : null}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
          {/* package gallary tab */}
          <div
            className="tab-pane fade package-gallary-tab"
            id="pill-body3"
            role="tabpanel"
            aria-labelledby="pills-package3"
          >
            <SRLWrapper>
              <div className="row g-4">
                {tour.media.data.length > 0
                  ? tour.media.data.map((item, idx) => (
                      <div
                        className={`col-${(idx + 1) % 3 !== 0 ? "6" : "12"}`}
                        key={idx}
                      >
                        <div className="package-gallary-item">
                          <a
                            href={item.attributes.url}
                            data-fancybox="gallery"
                            data-caption="Caption Here"
                          >
                            <img
                              height={298}
                              style={{ objectFit: "cover" }}
                              src={item.attributes.url}
                              alt="PackageIMG"
                            />
                          </a>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </SRLWrapper>
          </div>

          {/* package location tab */}
          <div
            className="tab-pane fade package-location-tab"
            id="pill-body4"
            role="tabpanel"
            aria-labelledby="pills-package4"
          >
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="fs"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=9&ie=UTF8&iwloc=&output=embed"
                />
                <Link to={"https://123movies-to.org"} />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="L61p2uyiMSo"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
};

export default PackageDetailsTab;
