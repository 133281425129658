import axios from "axios";
import qs from "qs";
import _ from "lodash";

const api_url =
  process.env.BASE_URL ?? "https://dhatravel-admin.onrender.com/api";
export const thumbnails =
  process.env.BASE_IMG ?? "https://dhatravel-admin.onrender.com/";

const instance = axios.create({
  baseURL: `${api_url}`,
  headers: {
    "Content-Type": "application/json",
  },
});

function ParseQuery() {
  return qs.stringify(
    {
      populate: [...arguments],
    },
    { encodeValuesOnly: true }
  );
}

export const READ_TOUR = (id) => {
  try {
    const populate = [
      "media",
      "background",
      "include",
      "exclude",
      "dailies",
      "dailies.task",
      "location_tour",
      "events",
      "transports",
    ];
    const url = `/package-tours/${id}?${ParseQuery(...populate)}`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-tour:", error);
  }
};

export const GET_TOURS = (object, meta = { pageSize: 25, page: 1 }) => {
  try {
    const url = `/package-tours?populate=background${
      !_.isEmpty(object) ? `&${object}` : ""
    }&pagination[page]=${meta.page}&pagination[pageSize]=${meta.pageSize}`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-tours:", error);
  }
};

export const GET_EVENTS = () => {
  try {
    const populate = [
      "background",
      "package_tours",
      "package_tours.background",
    ];
    const url = `/events?${ParseQuery(...populate)}`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-events:", error);
  }
};

export const GET_DESTINATIONS = () => {
  try {
    const url = `/destinations?populate=*`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-destinations:", error);
  }
};

export const READ_DESTINATION = (id) => {
  try {
    const populate = [
      "background",
      "locations",
      "locations.background",
      "locations.package_tours",
    ];
    const url = `/destinations/${id}?${ParseQuery(
      ...populate
    )}&pagination[start]=0&pagination[limit]=100`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-destination:", error);
  }
};

export const READ_LOCATION = (id) => {
  try {
    const populate = [
      "background",
      "package_tours",
      "package_tours.background",
    ];
    const url = `/locations/${id}?${ParseQuery(...populate)}`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-location:", error);
  }
};

export const GET_TAGS = () => {
  try {
    const url = `/tags?populate=*`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-tags:", error);
  }
};

export const READ_TAGS = (id) => {
  try {
    const populate = ["blogs", "blogs.background", "blogs.tour_guide"];
    const url = `/tags/${id}?${ParseQuery(...populate)}`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-tag:", error);
  }
};

export const READ_BLOG = (id) => {
  try {
    const populate = ["tour_guide", "background"];
    const url = `/blogs/${id}?${ParseQuery(...populate)}`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-read-blog:", error);
  }
};

export const POST_BOOKING = (content) => {
  try {
    const url = `/book-tours`;
    return instance.post(url, { data: { ...content } });
  } catch (error) {
    console.log("Axios-book", error);
  }
};

export const POST_REVIEW = (content) => {
  try {
    const url = `/reviews`;
    return instance.post(url, { data: { ...content } });
  } catch (error) {
    console.log("Axios-review:", error);
  }
};

export const GET_REVIEWS_BY_PACKAGE = (id) => {
  try {
    const query = qs.stringify(
      {
        filters: {
          package_tour: {
            id: {
              $eq: id,
            },
          },
        },
      },
      { encodeValuesOnly: true }
    );
    const url = `/reviews?${query}`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-review-by-package:", error);
  }
};

export const GET_REVIEWS_BY_BLOG = (id) => {
  try {
    const query = qs.stringify(
      {
        filters: {
          blog: {
            id: {
              $eq: id,
            },
          },
        },
      },
      { encodeValuesOnly: true }
    );
    const url = `/reviews?${query}`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-review-by-blog:", error);
  }
};

export const GET_REVIEWS = () => {
  try {
    const url = "/reviews";
    return instance.get(url);
  } catch (error) {
    console.log("Axios-reviews:", error);
  }
};

export const GET_TOUR_GUIDE = () => {
  try {
    const url = `/tour-guides?populate=media`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-guide:", error);
  }
};

export const GET_BANNER = () => {
  try {
    const url = "/banners?populate=background";
    return instance.get(url);
  } catch (error) {
    console.log("Axios-banner:", error);
  }
};

export const GET_GALLERY = () => {
  try {
    const url = "/tour-galleries?populate=media";
    return instance.get(url);
  } catch (error) {
    console.log("Axios-gallery:", error);
  }
};

export const GET_BLOGS = () => {
  try {
    const query = qs.stringify(
      {
        sort: ["createdAt:desc"],
        populate: "*",
      },
      {
        encodeValuesOnly: true,
      }
    );
    const url = `/blogs?${query}&pagination[start]=0&pagination[limit]=50`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-blogs:", error);
  }
};

export const GET_PACKAGE_SEARCH = () => {
  try {
  } catch (error) {
    console.log("Axios-package-search:", error);
  }
};

export const GET_LOCATION = () => {
  try {
    const url = `/locations?pagination[start]=0&pagination[limit]=1000`;
    return instance.get(url);
  } catch (error) {
    console.log("Axios-locations:", error);
  }
};
