import React, { useEffect, useState } from "react";
import { GET_TOURS } from "../../../api-client";
import PackageCard from "../packageGrid/PackageCard";

function OfferArea() {
  const [tourOffer, setTourOffer] = useState([]);

  const getTourOffer = () => {
    GET_TOURS()
      .then(({ data, status }) => {
        if (status === 200) {
          const arrTour = data.data.filter((item) => item.attributes.offer);
          setTourOffer(arrTour);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTourOffer();
  }, []);

  return (
    <>
      <div className="package-area package-style-one pt-110 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-head-alpha text-center mx-auto">
                <h2 style={{ textTransform: "uppercase" }}>
                  ưu đãi cho các ngày lễ
                </h2>
                <p>
                  Các chương trình ưu đãi luôn được cập nhật thường xuyên và vô
                  cùng hấp dẫn.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {tourOffer.length > 0
              ? tourOffer.map(
                  (item, idx) =>
                    idx < 6 && (
                      <div className="col-lg-4 col-md-6" key={idx}>
                        <PackageCard tour={item} />
                      </div>
                    )
                )
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default OfferArea;
