import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GET_TAGS } from "../../../../api-client";

function BlogWidgetCategories() {
  const [tags, setTags] = useState([]);
  const getTags = () => {
    GET_TAGS()
      .then(({ data, status }) => {
        status === 200 && setTags(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <>
      <aside className="blog-widget widget-categories mt-30">
        <div className="widget-title">
          <h4>Tìm hiểu về dịch vụ</h4>
        </div>
        <ul className="widget-body">
          {tags.map((item, idx) => (
            <li key={idx}>
              <Link to={`/blogs/${item.id}`}>
                <h6>
                  <i className="bi bi-chevron-double-right" />{" "}
                  {item.attributes.tag_name}
                </h6>
                <span>({item.attributes.blogs.data.length})</span>
              </Link>
            </li>
          ))}
        </ul>
      </aside>
    </>
  );
}

export default BlogWidgetCategories;
