import * as dayjs from "dayjs";
import customParserFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParserFormat);

export const dayFormat = (time) => dayjs(time).format("DD-MM-YYYY HH:MM");

export const timeFormat = (time) => dayjs(time).format("YYYY-MM-DD");

export const monthFormat = (time) => dayjs(time).format("MMMM DD, YYYY");

export const moneyFormat = (x) => {
  const value = x.toLocaleString("it-IT", {
    style: "currency",
    currency: "VND",
  });
  return `${value}`;
};
