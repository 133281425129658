import React from "react";
import StandardCard from "./StandardCard";
// import Pagination from "../../../common/Pagination";
// import SearchWidget from "./SearchWidget";
import BlogWidgetCategories from "./BlogWidgetCategories";
import BlogNewPostWidget from "./BlogNewPostWidget";
// import TagWidget from "./TagWidget";
import GalleryWidget from "./GallaryWidget";
import { monthFormat } from "../../../functions";

function BlogStandardWrap({ blogs }) {
  return (
    <>
      <div className="blog-sidebar-wrapper pt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {blogs.length > 0 &&
                blogs
                  .sort((a, b) => b.id - a.id)
                  .map((item, idx) => (
                    <StandardCard
                      key={idx}
                      to={item.id}
                      image={
                        item.attributes.background.data?.attributes?.url ||
                        process.env.PUBLIC_URL +
                          "/images/blog/blog-thumb-xl1.png"
                      }
                      writer={
                        item.attributes.tour_guide.data?.attributes?.name ||
                        "Admin"
                      }
                      date={monthFormat(item.attributes.createdAt)}
                      title="Sed nec lacinia sapien. Sed pretium lorem at leo Sed necles lacinia sapien. Sed pretium lorem at leo pellentesque."
                    />
                  ))}
              {/* <StandardCard
                image={
                  process.env.PUBLIC_URL + "/images/blog/blog-thumb-xl1.png"
                }
                writter="John Smith"
                date="September 10, 2022"
                title="Sed nec lacinia sapien. Sed pretium lorem at leo Sed necles lacinia sapien. Sed pretium lorem at leo pellentesque."
              /> */}
              {/* <Pagination /> */}
            </div>
            <div className="col-lg-4">
              <div className="blog-sidebar">
                {/* <SearchWidget /> */}
                <BlogWidgetCategories />
                <BlogNewPostWidget />
                {/* <TagWidget /> */}
                <GalleryWidget />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogStandardWrap;
