import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import PackageGridWrapper from "./PackageGridWrapper";

function PackageGrid() {
  return (
    <>
      <Breadcrumb name="Gói Du Lịch" />
      <PackageGridWrapper />
    </>
  );
}

export default PackageGrid;
