import React, { useState, useEffect } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import { GET_GALLERY } from "../../../api-client";

function GalleryArea() {
  const [gallery, setGallery] = useState([]);

  const getGallery = () => {
    GET_GALLERY()
      .then(({ data, status }) => {
        status === 200 && setGallery(data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getGallery();
  }, []);

  return (
    <>
      <SRLWrapper>
        <div className="gallary-area gallary-style-one pt-110">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-head-alpha text-center mx-auto">
                  <h2>Các hình ảnh trong chuyến du lịch</h2>
                  <p>
                    Hơn 10 năm kinh nghiệm và đồng hành cùng 200.000+ khách
                    hàng. Hãy cùng nhìn lại những khoảnh khắc tuyệt vời mà chúng
                    tôi đã tạo nên.
                  </p>
                </div>
              </div>
            </div>
            <div className="gallery-wrapper">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="gallary-item">
                    <img
                      src={
                        (gallery.length > 0 &&
                          gallery[0].attributes?.media?.data[0]?.attributes
                            ?.url) ||
                        process.env.PUBLIC_URL + "/images/gallary/g-xl1.png"
                      }
                      height={450}
                      style={{ objectFit: "cover" }}
                      alt="ImageGallery"
                    />
                  </div>
                  <div className="gallary-item">
                    <img
                      src={
                        (gallery.length > 0 &&
                          gallery[0].attributes?.media?.data[1]?.attributes
                            ?.url) ||
                        process.env.PUBLIC_URL + "/images/gallary/g-md1.png"
                      }
                      height={300}
                      style={{ objectFit: "cover" }}
                      alt="ImageGallery"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="gallary-item">
                    <img
                      src={
                        (gallery.length > 0 &&
                          gallery[0].attributes?.media?.data[2]?.attributes
                            ?.url) ||
                        process.env.PUBLIC_URL + "/images/gallary/g-sm1.png"
                      }
                      height={208}
                      alt="ImageGallery"
                    />
                  </div>
                  <div className="gallary-item">
                    <img
                      src={
                        (gallery.length > 0 &&
                          gallery[0].attributes?.media?.data[3]?.attributes
                            ?.url) ||
                        process.env.PUBLIC_URL + "/images/gallary/g-sm2.png"
                      }
                      height={208}
                      style={{ objectFit: "cover" }}
                      alt="ImageGallery"
                    />
                  </div>
                  <div className="gallary-item">
                    <img
                      src={
                        (gallery.length > 0 &&
                          gallery[0].attributes?.media?.data[4]?.attributes
                            ?.url) ||
                        process.env.PUBLIC_URL + "/images/gallary/g-md2.png"
                      }
                      height={300}
                      style={{ objectFit: "cover" }}
                      alt="ImageGallery"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="gallary-item">
                    <img
                      src={
                        (gallery.length > 0 &&
                          gallery[0].attributes?.media?.data[5]?.attributes
                            ?.url) ||
                        process.env.PUBLIC_URL + "/images/gallary/g-xl2.png"
                      }
                      height={450}
                      style={{ objectFit: "cover" }}
                      alt="ImageGallery"
                    />
                  </div>
                  <div className="gallary-item">
                    <img
                      src={
                        (gallery.length > 0 &&
                          gallery[0].attributes?.media?.data[6]?.attributes
                            ?.url) ||
                        process.env.PUBLIC_URL + "/images/gallary/g-md3.png"
                      }
                      height={300}
                      style={{ objectFit: "cover" }}
                      alt="ImageGallery"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}

export default GalleryArea;
