import React, { useEffect, useState } from "react";
import { GET_TOURS } from "../../../api-client";
import { Link } from "react-router-dom";
import { moneyFormat } from "../../functions";

function WidgetPackageOffer() {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [tourOffer, setTourOffer] = useState([]);

  const getTourOffer = () => {
    GET_TOURS()
      .then(({ data, status }) => {
        if (status === 200) {
          const arrTour = data.data.filter((item) => item.attributes.offer);
          setTourOffer(arrTour);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTourOffer();
  }, []);

  return (
    <>
      <aside className="package-widget-style-2 widget-recent-package-entries mt-30">
        <div className="widget-title text-center">
          <h4>Ưu đãi</h4>
        </div>
        <div className="widget-body">
          <ul>
            {tourOffer.length > 0
              ? tourOffer
                  .filter((_, idx) => idx < 4)
                  .map((item, idx) => (
                    <li className="package-sm" key={idx}>
                      <div className="thumb">
                        <Link onClick={scrollTop} to={item?.id}>
                          <img
                            src={`${item.attributes.background.data?.attributes?.url}`}
                            height={64}
                            style={{ objectFit: "cover" }}
                            alt="PackageIMG"
                          />
                        </Link>
                      </div>
                      <div className="info">
                        <h6>
                          <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/package-details`}
                          >
                            {item.attributes.name}
                          </Link>
                        </h6>
                        <div className="price">
                          <br />
                          <h6>
                            {moneyFormat(item.attributes.price_person)}{" "}
                            <span>Mỗi người</span>
                          </h6>
                        </div>
                      </div>
                    </li>
                  ))
              : null}
          </ul>
        </div>
      </aside>
    </>
  );
}

export default WidgetPackageOffer;
