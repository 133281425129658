import React from "react";
import DestinationCart from "./DestinationCart";

function DestinationWrapper({ locations }) {
  return (
    <>
      <div className="destination-wrapper pt-110">
        <div className="container">
          <div className="row g-3">
            {locations.data?.length > 0
              ? locations.data.map((item, idx) => (
                  <div key={idx} className="col-lg-3 col-md-4 col-sm-6">
                    <DestinationCart
                      image={
                        item.attributes.background.data?.attributes?.url ||
                        process.env.PUBLIC_URL +
                          `/images/destination/des-sm${
                            ((idx + 1) % 10) + 1
                          }.png`
                      }
                      to={`${process.env.PUBLIC_URL}/package/${item.id}`}
                      place={item.attributes.name}
                      placeCount={item.attributes.package_tours.data.length}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default DestinationWrapper;
