import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  GET_REVIEWS_BY_BLOG,
  READ_BLOG,
  POST_REVIEW,
} from "../../../../api-client";
import _ from "lodash";
import { monthFormat } from "../../../functions";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

function BlogDetailsLeft() {
  const { register, handleSubmit, reset } = useForm();
  const params = useParams();
  const [blog, setBlog] = useState({});
  const [reviews, setReviews] = useState([]);

  const getBlog = (id) => {
    READ_BLOG(id)
      .then(({ data, status }) => {
        if (status === 200) {
          setBlog(data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getComment = (id) => {
    GET_REVIEWS_BY_BLOG(id)
      .then(({ data, status }) => {
        status === 200 && setReviews(data.data);
      })
      .catch((err) => console.log(err));
  };

  const notify = (mess) =>
    toast.error(mess || "Bạn cần điền đầy đủ thông tin!");
  const onHandleSubmit = (values) => {
    const data = {
      ...values,
      blog: params.id,
    };
    POST_REVIEW(data)
      .then(() => {
        toast.success("Nhận xét thành công!");
        reset();
        getComment(params.id);
      })
      .catch(() => {
        toast.error("Không thực hiện được chức năng!");
      });
  };
  const onHandleErrors = () => {
    notify();
  };

  useEffect(() => {
    getBlog(params.id);
    getComment(params.id);
  }, [params]);

  if (_.isEmpty(blog)) {
    return <></>;
  }

  return (
    <>
      <ToastContainer />
      <div className="blog-details">
        <div className="post-header">
          <h2 className="post-title">{blog.attributes.title}</h2>
          <div className="post-meta">
            <Link to={"#"} className="blog-writer">
              <i className="bi bi-person-circle" /> Tác giả{" "}
              {blog.attributes.tour_guide.data?.attributes?.name}
            </Link>
            <Link to={"#"} className="blog-comments">
              <i className="bi bi-calendar3" />{" "}
              {monthFormat(blog.attributes.updatedAt)}
            </Link>
            <Link to={"#"} className="blog-comments">
              <i className="bi bi-chat-left-quote" /> Bình luận (
              {reviews.length || 0})
            </Link>
          </div>
        </div>

        <div className="post-thumb">
          <img
            src={
              blog.attributes.background.data?.attributes?.url ||
              process.env.PUBLIC_URL + "/images/blog/blog-thumb-xl1.png"
            }
            alt="blogDetailsIMG"
          />
        </div>
        <div className="post-header"></div>

        <div
          className="post-body"
          style={{ marginTop: 40 }}
          dangerouslySetInnerHTML={{ __html: blog.attributes.description }}
        ></div>

        {/* <div className="post-footer flex-wrap flex-md-nowrap">
            <Link to={"#"} className="post-switcher prev-post">
              <i className="bi bi-chevron-left" /> Previous
            </Link>
            <Link to={"#"} className="next-post-link">
              Hendrerit a id lectus. Suspendissendt blandit interdum. Sed
              pellentesque at nunc eget consente.
            </Link>
            <Link to={"#"} className="post-switcher next-post">
              Next <i className="bi bi-chevron-right" />
            </Link>
          </div> */}
      </div>
      <div className="comment-section">
        <h4>Bình luận ({reviews.length || 0})</h4>
        <ul className="comment-list">
          {reviews.map((item, idx) => (
            <li className="single-comment d-flex flex-sm-row flex-column ">
              <div className="commmentor">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/reviewer/commertor2.png"
                  }
                  alt="BlogDetails"
                />
              </div>
              <div className="comment mt-4 mt-sm-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="info">
                    <h6>{item.attributes.name}</h6>
                    <span>{monthFormat(item.attributes.createdAt)}</span>
                  </div>
                  <ul className="rating d-flex ">
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                    <li>
                      <i className="bi bi-star-fill" />
                    </li>
                  </ul>
                </div>
                <p>{item.attributes.content}</p>
                {/* <div className="reply-btn">
                  <Link to={"#"}>
                    <i className="bi bi-reply-all-fill" /> Reply
                  </Link>
                </div> */}
              </div>
            </li>
          ))}
        </ul>
        {/* <div className="comment-btn text-center">
            <Link to={"#"}>View All Comment</Link>
          </div> */}
      </div>
      <form
        onSubmit={handleSubmit(onHandleSubmit, onHandleErrors)}
        id="comment_form"
        method="post"
      >
        <div className="comment-form" style={{ marginBottom: 30 }}>
          <h4>Hãy để lại bình luận của bạn</h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <input
                  type="text"
                  placeholder="Họ tên"
                  id="name"
                  {...register("name", { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group">
                <input
                  type="text"
                  placeholder="Email"
                  id="mail"
                  {...register("mail", { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <select
                  placeholder="Điểm"
                  id="rate"
                  {...register("rate", { required: true })}
                >
                  <option value="five">5 sao</option>
                  <option value="four">4 sao</option>
                  <option value="three">3 sao</option>
                  <option value="two">2 sao</option>
                  <option value="one">1 sao</option>
                </select>
              </div>
            </div>
          </div>
          <div className="custom-input-group">
            <textarea
              cols={20}
              rows={7}
              {...register("content", { required: true })}
              placeholder="Nhận xét"
              defaultValue={""}
            />
          </div>
          <div className="custom-input-group">
            <div className="submite-btn">
              <button type="submit">Gửi nhận xét</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default BlogDetailsLeft;
