import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { READ_LOCATION } from "../../../api-client";
import Breadcrumb from "../../common/Breadcrumb";
import PackageGridWrapper from "./PackageGridWrapper";

function PackageGrid() {
  const params = useParams();
  const [tours, setTours] = useState([]);

  const readLocation = () => {
    READ_LOCATION(params.id)
      .then(({ data, status }) => {
        if (status === 200) {
          setTours(data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    readLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (_.isEmpty(tours)) return <></>;

  return (
    <>
      <Breadcrumb name={`Du lịch ${tours.attributes.name}`} />
      <PackageGridWrapper tours={tours.attributes.package_tours.data} />
    </>
  );
}

export default PackageGrid;
