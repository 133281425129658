import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { moneyFormat, timeFormat } from "../../functions";
import { POST_BOOKING } from "../../../api-client";
import _ from "lodash";

function WidgetForm({ tour, id }) {
  const [date, setDate] = useState(new Date());
  const { register, handleSubmit, reset } = useForm();

  const notify = (mess) =>
    toast.error(mess || "Bạn cần điền đầy đủ thông tin!");

  const onHandleSubmit = (values) => {
    const data = {
      ...values,
      book_start_time: timeFormat(date),
      package_tour: id,
      book_group_size: +values.book_group_size,
      book_guide_size: +values.book_guide_size,
    };
    POST_BOOKING(data)
      .then(() => {
        toast.success("Đặt lịch thành công!");
        reset();
      })
      .catch(() => notify("Không thực hiện được chức năng!"));
  };
  const onHandleErrors = () => {
    notify();
  };

  if (_.isEmpty(tour)) return <></>;

  return (
    <>
      <ToastContainer />
      <aside className="package-widget-style-2 widget-form">
        <div className="widget-title text-center d-flex justify-content-between">
          <h4>Đặt lịch</h4>
          <h3 className="widget-lavel">
            {moneyFormat(tour.price_person)} <span>Mỗi người</span>
          </h3>
        </div>
        <div className="widget-body">
          <form
            onSubmit={handleSubmit(onHandleSubmit, onHandleErrors)}
            action="#"
            method="post"
            id="booking-form"
          >
            <div className="booking-form-wrapper">
              <div className="custom-input-group">
                <input
                  type="text"
                  placeholder="Họ và tên khách hàng"
                  id="custom_name"
                  {...register("custom_name", { required: true })}
                />
              </div>
              <div className="custom-input-group">
                <input
                  type="email"
                  placeholder="Email khách hàng"
                  id="custom_mail"
                  {...register("custom_mail", { required: true })}
                />
              </div>
              <div className="custom-input-group">
                <input
                  type="tel"
                  placeholder="Số điện thoại khách hàng"
                  id="custom_phone"
                  {...register("custom_phone", { required: true })}
                />
              </div>
              <div className="custom-input-group">
                <input
                  type="number"
                  placeholder="Số lượng khách"
                  id="book_group_size"
                  {...register("book_group_size", { required: true })}
                />
              </div>
              <div className="custom-input-group">
                <input
                  type="number"
                  placeholder="Số lượng hướng dẫn viên mong muốn"
                  id="book_guide_size"
                  {...register("book_guide_size")}
                />
              </div>
              <div className="custom-input-group">
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  className="input-field check-in"
                  placeholder="dd-mm-yy"
                />
              </div>
              <div className="custom-input-group">
                <textarea
                  cols={20}
                  rows={7}
                  placeholder="Ghi chú"
                  defaultValue={""}
                  {...register("custom_note")}
                />
              </div>
              <div className="custom-input-group">
                <div className="submite-btn">
                  <button type="submit">Đặt lịch</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </aside>
    </>
  );
}

export default WidgetForm;
