import React from "react";
import { Link } from "react-router-dom";

function DestinationCart(props) {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="destination-card-style-two mb-0">
        <div className="d-card-thumb">
          <img
            src={props.image}
            width={310}
            height={290}
            style={{ objectFit: "cover" }}
            alt="CartIMG hi"
          />
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            height: "240px",
            width: "100%",
            backgroundImage: "linear-gradient(#5c5c5c00, #020202c9)",
          }}
        ></div>
        <div className="d-card-content" style={{ padding: "0px 10px" }}>
          <h4 className="destination-title">
            {" "}
            <Link onClick={scrollTop} to={props.to}>
              {props.place}
            </Link>
          </h4>
          <div className="place-count">
            <span>{props.placeCount}</span> Địa điểm
          </div>
        </div>
      </div>
    </>
  );
}

export default DestinationCart;
