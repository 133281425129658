import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation } from "swiper";
import HomeOneDestinationCart from "./HomeOneDestinationCart";
import { useState } from "react";
import { GET_DESTINATIONS } from "../../../api-client";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

function DestinationArea() {
  const [dest, setDest] = useState([]);

  const getDestinations = () => {
    GET_DESTINATIONS()
      .then(({ data, status }) => {
        if (status === 200) setDest(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDestinations();
  }, []);

  const destinationSlider = {
    slidesPerView: 1,
    speed: 2000,
    spaceBetween: 24,
    loop: true,
    roundLengths: true,
    centeredSlides: true,
    autoplay: {
      delay: 1500,
    },
    navigation: {
      nextEl: ".testi-next",
      prevEl: ".testi-prev",
    },
    breakpoints: {
      480: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  };

  return (
    <>
      {/* =============== destination area start =============== */}
      <div className="destination-area destination-style-one pt-110 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="section-head-alpha">
                <h2>Khám phá điểm đến hấp dẫn</h2>
                <p>
                  Điểm đến và chương trình tour luôn được đổi mới để phù hợp với
                  mọi khách hàng dù là khách hàng khó tính nhất
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="slider-arrows text-center d-lg-flex d-none justify-content-end mb-3">
                <div
                  className="testi-prev custom-swiper-prev"
                  tabIndex={0}
                  role="button"
                  aria-label="Previous slide"
                >
                  <i className="bi bi-chevron-left" />
                </div>
                <div
                  className="testi-next custom-swiper-next"
                  tabIndex={0}
                  role="button"
                  aria-label="Next slide"
                >
                  <i className="bi bi-chevron-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0 overflow-hidden">
          <Swiper
            {...destinationSlider}
            className="swiper destination-slider-one"
          >
            <div className="swiper-wrapper">
              {dest.length > 0
                ? dest.map((item, idx) => (
                    <SwiperSlide className="swiper-slide" key={idx}>
                      <HomeOneDestinationCart
                        to={`destination/${item.id}/${
                          item.attributes.is_domestic ? "in" : "out"
                        }`}
                        image={
                          item.attributes.background.data?.attributes?.url ||
                          process.env.PUBLIC_URL +
                            "/images/destination/dest-3.png"
                        }
                        title={item.attributes.name}
                        place={item.attributes.locations.data.length}
                        hotel="16"
                      />
                    </SwiperSlide>
                  ))
                : null}
            </div>
          </Swiper>
        </div>
      </div>
      {/* =============== destination area  end =============== */}
    </>
  );
}

export default DestinationArea;
