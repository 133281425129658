import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_EVENTS, GET_TAGS } from "../../../api-client";

function WidgetPackageTag() {
  const [tags, setTags] = useState([]);
  const [event, setEvent] = useState([]);

  const getTags = () => {
    GET_TAGS()
      .then(({ data, status }) => {
        if (status === 200) setTags(data.data);
      })
      .catch((err) => console.log(err));
  };

  const getEvents = () => {
    GET_EVENTS()
      .then(({ data, status }) => {
        if (status === 200) setEvent(data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getTags();
    getEvents();
  }, []);

  return (
    <>
      <aside className="package-widget-style-2 widget-tag-cloud mt-30">
        <div className="widget-title text-center">
          <h4>Sự kiện</h4>
        </div>
        <div className="tag-cloud widget-body">
          {event?.length > 0
            ? event?.map((item, idx) => (
                <Link
                  key={idx}
                  to={process.env.PUBLIC_URL + `/events/${item.id}`}
                >
                  {item.attributes.event_name}
                </Link>
              ))
            : null}
        </div>
      </aside>
      <aside className="package-widget-style-2 widget-tag-cloud mt-30">
        <div className="widget-title text-center">
          <h4>Xem thêm</h4>
        </div>
        <div className="tag-cloud widget-body">
          {tags?.length > 0
            ? tags?.map((item, idx) => (
                <Link
                  key={idx}
                  to={process.env.PUBLIC_URL + `/package/${item.id}`}
                >
                  {item.attributes.tag_name}
                </Link>
              ))
            : null}
        </div>
      </aside>
    </>
  );
}

export default WidgetPackageTag;
