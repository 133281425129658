import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import GuideWrap from "./GuideWrap";

function GuidePage() {
  return (
    <>
      <Breadcrumb name="Hướng dẫn viên du lịch" />
      <GuideWrap />
    </>
  );
}

export default GuidePage;
