import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GET_TOUR_GUIDE } from "../../api-client";
import GuideCardGama from "./GuideCardGama";

function GuideArea() {
  const [guide, setGuide] = useState([]);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getGuide = () => {
    GET_TOUR_GUIDE()
      .then(({ data, status }) => {
        if (status === 200) {
          setGuide(() => {
            const arrGuide = data?.data;
            const result =
              arrGuide.length > 3 ? arrGuide.slice(0, 3) : arrGuide;
            return result;
          });
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getGuide();
  }, []);

  return (
    <>
      <div className="guide-area guide-style-one pt-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-head-gamma text-start">
                <h2>Hướng dẫn viên</h2>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="blog-btn text-lg-end">
                <Link
                  to={`${process.env.PUBLIC_URL}/guide`}
                  onClick={scrollTop}
                  className="button-fill-round"
                >
                  Xem tất cả
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {guide?.length > 0
              ? guide?.map((item, idx) => (
                  <div className="col-lg-4 col-md-6" key={idx}>
                    <GuideCardGama guide={item?.attributes} />
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default GuideArea;
