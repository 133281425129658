import _ from "lodash";
import React from "react";

function GuideCardGama({ guide: props }) {
  if (_.isEmpty(props)) return <></>;

  return (
    <>
      <div className="guide-card-gamma">
        <div className="guide-image">
          <img
            src={props.media.data.attributes.url}
            height={374}
            style={{ objectFit: "cover" }}
            alt="GuideIMG"
          />
          <ul className="guide-social-links">
            {/* <li>
              <a
                rel="noopener noreferrer"
                href="https://www.instagram.com/"
                target="_blank"
              >
                <i className="bx bxl-instagram" />
              </a>
            </li> */}
            <li>
              <a
                rel="noopener noreferrer"
                href={props.social_net_link}
                target="_blank"
              >
                <i className="bx bxl-facebook" />
              </a>
            </li>
            {/* <li>
              <a
                rel="noopener noreferrer"
                href="https://www.twitter.com/"
                target="_blank"
              >
                <i className="bx bxl-twitter" />
              </a>
            </li> */}
          </ul>
          <div className="contact-lavel">
            <a rel="noopener noreferrer" href={"tel:+" + props.phone}>
              Liên hệ
            </a>
          </div>
        </div>
        <div className="guide-content">
          <h4 className="guide-name">{props.name}</h4>
          <h6 className="guide-designation">Hướng dẫn viên</h6>
        </div>
      </div>
    </>
  );
}

export default GuideCardGama;
