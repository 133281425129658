import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { READ_TAGS } from "../../../../api-client";
import Breadcrumb from "../../../common/Breadcrumb";
import BlogStandardWrap from "./BlogStandardWrap";

function BlogStandard() {
  const params = useParams();
  const [tag, setTag] = useState();

  const getTag = (id) => {
    READ_TAGS(id)
      .then(({ data, status }) => {
        if (status === 200) {
          setTag(data.data.attributes);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTag(params.id);
  }, [params]);

  return (
    <>
      <Breadcrumb name={tag?.tag_name} />
      <BlogStandardWrap blogs={tag?.blogs?.data || []} />
    </>
  );
}

export default BlogStandard;
