import React, { useState, useEffect } from "react";
import { GET_BLOGS } from "../../../api-client";
import { monthFormat } from "../../functions";
import BlogCardAlpha from "./BlogCardAlpha";

function LatestNews() {
  const [blogs, setBlogs] = useState([]);

  const getBlog = () => {
    GET_BLOGS()
      .then(({ data, status }) => {
        status === 200 && setBlogs(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBlog();
  }, []);

  return (
    <>
      {/* =============== Blog area start =============== */}
      <div className="blog-area blog-style-one pt-110  ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-head-alpha text-center mx-auto">
                <h2>Tin Tức Mới Nhất</h2>
                <p>
                  Luôn cập nhật những thông tin du lịch về điểm đến hot trong và
                  ngoài nước.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {blogs
              .sort((a, b) => b.id - a.id)
              .filter((_, idx) => idx < 3)
              .map((item, idx) => (
                <div key={idx} className="col-lg-4 col-md-6">
                  <BlogCardAlpha
                    to={item.id}
                    image={
                      item.attributes.background?.data?.attributes?.url ||
                      process.env.PUBLIC_URL + "/images/blog/blog-md-1.png"
                    }
                    date={monthFormat(item.attributes.createdAt)}
                    name={item.attributes.tour_guide?.data?.attributes?.name}
                    comments={item.attributes.reviews?.data?.length}
                    title={item.attributes.title}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* =============== Blog area end =============== */}
    </>
  );
}

export default LatestNews;
