import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import PackageDetailsWrap from "./PackageDetailsWrap";

function PackageDetails() {
  return (
    <>
      <Breadcrumb name="Chi tiết chuyến du lịch" />
      <PackageDetailsWrap />
    </>
  );
}

export default PackageDetails;
