import React from "react";
import { Link } from "react-router-dom";
import { thumbnails } from "../../../api-client";
import { moneyFormat } from "../../functions";

function PackageCard({ tour: { attributes: data, id } }) {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="package-card-alpha">
        <div className="package-thumb">
          <Link
            onClick={scrollTop}
            to={`${process.env.PUBLIC_URL}/package-details/${id}`}
          >
            <img
              src={
                process.env.PUBLIC_URL + "/images/package/best-s1.png" ||
                thumbnails + data?.media?.data[0]?.attributes?.url
              }
              height={286}
              style={{ objectFit: "cover" }}
              alt="images"
            />
          </Link>
          <p className="card-lavel">
            <i className="bi bi-clock" />{" "}
            <span>
              {data.duration} ngày {data.duration - 1} đêm
            </span>
          </p>
        </div>
        <div className="package-card-body">
          <h3 className="p-card-title">
            <Link
              onClick={scrollTop}
              to={`${process.env.PUBLIC_URL}/package-details/${id}`}
            >
              {data.name}
            </Link>
          </h3>
          <div className="p-card-bottom">
            <div className="book-btn">
              <Link
                onClick={scrollTop}
                to={`${process.env.PUBLIC_URL}/package-details/${id}`}
              >
                Đặt lịch <i className="bx bxs-right-arrow-alt" />
              </Link>
            </div>
            <div className="p-card-info">
              <h6>
                {moneyFormat(data.price_person)} vnđ
                <br />
                <span>Mỗi người</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageCard;
