import React, { useState, useEffect } from "react";
import { GET_TOUR_GUIDE } from "../../../api-client";
import GuideCardAlpha from "./GuideCardAlpha";

function TourGideArea() {
  const [guide, setGuide] = useState([]);

  const getGuide = () => {
    GET_TOUR_GUIDE()
      .then(({ data, status }) => {
        if (status === 200) {
          setGuide(data.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getGuide();
  }, []);

  return (
    <>
      <div className="guide-area guide-style-one pt-110">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-head-alpha text-center mx-auto">
                <h2>Hướng dẫn viên du lịch</h2>
                <p>
                  Đội ngũ hướng dẫn viên trẻ, nhiệt tình, năng động, trách
                  nhiệm, nhiều năm kinh nghiệm và chuyên nghiệp trong từng
                  chuyến đi!
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {guide.length > 0
              ? guide
                  .sort(() => Math.random() - 0.5)
                  .filter((_, idx) => idx < 3)
                  .map((item, idx) => (
                    <div className="col-lg-4 col-md-6" key={idx}>
                      <GuideCardAlpha guide={item.attributes} />
                    </div>
                  ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default TourGideArea;
