import React from "react";
import { Link } from "react-router-dom";

function Pagination({ meta, setMeta }) {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <nav>
            <ul className="pagination pagination-style-one justify-content-center pt-50">
              {/* <li className="page-item page-arrow">
                <Link to={"#"} onClick={scrollTop} className="page-link">
                  <i className="bi bi-chevron-double-left" />
                </Link>
              </li> */}

              {Array.from({ length: meta.pageCount }, (_, idx) => (
                <li
                  className="page-item active"
                  key={idx}
                  onClick={() => {
                    setMeta((meta) => ({ ...meta, page: idx + 1 }));
                  }}
                >
                  <Link
                    to={"#"}
                    onClick={scrollTop}
                    className="page-link"
                    style={{ textAlign: "center" }}
                  >
                    {idx + 1}
                  </Link>
                </li>
              ))}

              {/* <li className="page-item page-arrow">
                <Link to={"#"} onClick={scrollTop} className="page-link">
                  <i className="bi bi-chevron-double-right" />
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Pagination;
