import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GET_BLOGS } from "../../../../api-client";
import { monthFormat } from "../../../functions";

function BlogNewPostWidget() {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [blogs, setBlogs] = useState([]);

  const getBlog = () => {
    GET_BLOGS()
      .then(({ data, status }) => {
        status === 200 && setBlogs(data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBlog();
  }, []);

  return (
    <>
      <aside className="blog-widget widget-recent-entries-custom mt-30">
        <div className="widget-title">
          <h4>Bài viết mới</h4>
        </div>
        <ul className="widget-body">
          {blogs
            .sort((a, b) => b.id - a.id)
            .filter((_, idx) => idx < 4)
            .map((item, idx) => (
              <li className="clearfix" key={idx}>
                <div className="wi">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/blog-details/${item.id}`}
                  >
                    <img
                      src={
                        item.attributes.background?.data?.attributes?.url ||
                        process.env.PUBLIC_URL +
                          "/images/blog/blog-thumb-sm1.png"
                      }
                      alt="BlogStandardIMG"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <h6>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/blog-details/${item.id}`}
                    >
                      {item.attributes.title}
                    </Link>
                  </h6>
                  <div className="wb-info">
                    <span className="post-date">
                      <i className="bi bi-person-circle" /> By{" "}
                      {item.attributes.tour_guide?.data?.attributes?.name}
                    </span>
                    <span className="post-date">
                      <i className="bi bi-calendar3" />{" "}
                      {monthFormat(item.attributes.createdAt)}
                    </span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </aside>
    </>
  );
}

export default BlogNewPostWidget;
