import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { useState } from "react";
import { GET_REVIEWS } from "../../api-client";
import { useEffect } from "react";
SwiperCore.use([Navigation, Autoplay]);

function TestimonialArea() {
  const testimonialSlider = {
    slidesPerView: 1,
    speed: 2000,
    spaceBetween: 20,
    loop: true,
    roundLengths: true,
    autoplay: {
      delay: 1500,
    },
    navigation: {
      nextEl: ".testi-next",
      prevEl: ".testi-prev",
    },
    breakpoints: {
      480: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };

  const [reviews, setReviews] = useState([]);

  const getReviews = () => {
    GET_REVIEWS()
      .then(({ data, status }) => {
        if (status === 200) {
          setReviews(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <>
      <div className="testimonial-area testimonial-style-one mt-110">
        <div className="testimonial-shape-group" />
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="section-head-alpha">
                <h2>Khách hàng nói gì về chúng tôi</h2>
                <p>
                  DHA Travel luôn lắng nghe những phản hồi, góp ý của khách hàng
                  để phục vụ tốt hơn.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="slider-arrows text-center d-lg-flex d-none justify-content-end mb-3">
                <div
                  className="testi-prev custom-swiper-prev"
                  tabIndex={0}
                  role="button"
                  aria-label="Previous slide"
                >
                  <i className="bi bi-chevron-left" />
                </div>
                <div
                  className="testi-next custom-swiper-next"
                  tabIndex={0}
                  role="button"
                  aria-label="Next slide"
                >
                  <i className="bi bi-chevron-right" />
                </div>
              </div>
            </div>
          </div>
          <Swiper
            {...testimonialSlider}
            className="swiper testimonial-slider-one position-relative"
          >
            <div className="swiper-wrapper">
              {reviews.length > 0
                ? reviews.map((item, idx) => (
                    <SwiperSlide className="swiper-slide" key={idx}>
                      <div className="testimonial-card testimonial-card-alpha">
                        <div className="testimonial-overlay-img">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/reviewer/reviewer.png"
                            }
                            alt="images"
                          />
                        </div>
                        <div className="testimonial-card-top">
                          <div className="qoute-icon">
                            <i className="bx bxs-quote-left" />
                          </div>
                          <div className="testimonial-thumb">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/images/reviewer/avatar_0${(idx % 5) + 1}.webp`
                              }
                              alt="images"
                            />
                          </div>
                          <h3 className="testimonial-count">{idx + 1}</h3>
                        </div>
                        <div className="testimonial-body">
                          <p>{item.attributes.content}</p>
                          <div className="testimonial-bottom">
                            <div className="reviewer-info">
                              <h4 className="reviewer-name">
                                {item.attributes.name}
                              </h4>
                              <h6>Du khách</h6>
                            </div>
                            <ul className="testimonial-rating">
                              <li>
                                <i className="bi bi-star-fill" />
                              </li>
                              <li>
                                <i className="bi bi-star-fill" />
                              </li>
                              <li>
                                <i className="bi bi-star-fill" />
                              </li>
                              <li>
                                <i className="bi bi-star-fill" />
                              </li>
                              <li>
                                <i className="bi bi-star-fill" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                : null}
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default TestimonialArea;
