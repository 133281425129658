import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutWrapperArea() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="about-main-wrappper pt-110">
        <div className="container">
          <div className="about-tab-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="about-tab-image-grid text-center">
                  <div className="about-video d-inline-block">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/introducer/travel-04.jpeg"
                      }
                      alt="aboutIMG"
                      width="307px"
                      height="284px"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                    <div className="video-overlay">
                      <div className="play-icon video-popup">
                        <i
                          onClick={() => setOpen(true)}
                          className="bi bi-play-fill"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row float-images g-4">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/introducer/travel-01.jpg"
                          }
                          alt="aboutIMG"
                          width="307px"
                          height="284px"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/introducer/travel-02.jpg"
                          }
                          alt="aboutIMG"
                          width="307px"
                          height="284px"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0">
                <div className="about-tab-wrap">
                  <h2 className="about-wrap-title">
                    <span>DHA Education</span> & Travel.
                  </h2>
                  <div className="about-tab-switcher">
                    <ul
                      className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true"
                        >
                          <h3>10+</h3>
                          <h6>Năm đồng hành</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                        >
                          <h3>8+</h3>
                          <h6>Lĩnh Vực</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                        >
                          <h3>500+</h3>
                          <h6>Giá trị đem lại</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                    >
                      <p style={{ fontSize: 18 }}>
                        Lời đầu tiên, Công ty TNHH Giáo Dục và Du Lịch DHA Việt
                        Nam (DHA Education & Travel) xin gửi tới quý khách hàng
                        lời chào, lời cảm ơn chân thành, lời chúc sức khỏe, sự
                        thành đạt. Đất nước ta đang trên đà hội nhập với nền
                        kinh tế toàn cầu, là một doanh nghiệp, năng động, chuyên
                        nghiệp trong lĩnh vực hỗ trợ giáo dục, du lịch và sự
                        kiện chúng tôi không ngừng nâng cao trình độ chuyên môn,
                        phát huy tính sáng tạo để ngày càng tạo ra nhiều sản
                        phẩm mới độc đáo hấp dẫn nhằm thỏa mãn mọi nhu cầu của
                        khách hàng. Hơn thế, được sự ủng hộ của quý khách suốt
                        thời gian qua cùng đội ngũ nhân viên nhiệt tình, năng
                        động với phong cách tổ chức chuyên nghiệp, công ty chúng
                        tôi đã dần lớn mạnh, khẳng định được vị trí trên thị
                        trường trong nước cũng như quốc tế trong các lĩnh vực
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                    >
                      <p style={{ fontSize: 18 }}>
                        1. Tổ chức các hoạt động ngoại khóa, trải nghiệm sáng
                        tạo học đường, giáo dục kỹ năng sống (trong và ngoài nhà
                        trường) <br></br>2. Tổ chức các tour trọn gói nội địa và
                        Quốc tế (Khách đoàn, lẻ, VIP,…) <br></br>3. Tổ chức các
                        sự kiện như : Hội nghị, hội thảo, Year End Party,
                        Teambuilding, Gala Dinner,… <br></br>4. Hỗ trợ và tư vấn
                        Visa đi các nước trên thế giới. <br></br>5. Nhận đặt
                        phòng trong nước và quốc tế. <br></br>6. Dịch vụ vé máy
                        bay của tất cả các hãng hàng không trong nước và quốc tế{" "}
                        <br></br>7. Dịch vụ vận chuyển (Xe từ 4 đến 45 chỗ){" "}
                        <br></br>8. Tư vấn miễn phí và tổ chức các tour du lịch,
                        sự kiện theo yêu cầu của khách hàng.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills3"
                      role="tabpanel"
                      aria-labelledby="pills-about3"
                    >
                      <p style={{ fontSize: 18 }}>
                        Với phương châm “uy tín, chất lượng là mối quan tâm hàng
                        đầu” DHA Education & Travel sẽ mang tới cho các bạn học
                        sinh những trải nghiệm tốt nhất, kỹ năng mềm hữu ích
                        nhất và mang đến quý khách hàng, những kỳ nghỉ vui vẻ bổ
                        ích, hấp dẫn, những hoạt động sự kiện chuyên nghiệp,
                        những bức ảnh và video đẹp, độc đáo nhất với giá cả hợp
                        lý và chất lượng tốt nhất. Công ty chúng tôi hiện nay đã
                        là hội viên chính thức của Hiệp Hội Du Lịch Việt Nam và
                        được vinh dự thực hiện rất nhiều những chương trình trải
                        nghiệm, du lịch, sự kiện của các nhà trường, cơ quan,
                        doanh nghiệp,…
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-110">
            <div className="col-lg-6">
              <div className="achievement-counter-wrap">
                <h2 className="about-wrap-title">
                  VĂN HÓA <span>DOANH NGHIỆP</span>
                </h2>
                <div className="achievement-counter-cards">
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center ">
                    <div className="counter-box mb-sm-0 mb-3">
                      <h2 style={{ fontSize: 24 }}>Tầm nhìn</h2>
                    </div>
                    <p>
                      Xây Dựng DHA Education & Travel trở thành doanh nghiệp
                      mạnh, chuyên nghiệp trong lĩnh vực hỗ trợ giáo dục và du
                      lịch với dịch vụ chất lượng và uy tín hang đầu Việt Nam.
                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    <div className="counter-box">
                      <h2 style={{ fontSize: 24 }}>Sứ mệnh</h2>
                    </div>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Đối với khách hàng:
                      </span>{" "}
                      Tôn trọng và cam kết mang đến cho khách hàng những dịch vụ
                      tốt nhất với giá trị cạnh tranh nhất.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Đối với đối tác:
                      </span>{" "}
                      Tôn trọng và cam kết mang đến cho khách hàng những dịch vụ
                      tốt nhất với giá trị cạnh tranh nhất.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Đối với nhân viên:
                      </span>{" "}
                      Tạo dựng môi trường làm việc than thiện, chuyên nghiệp,
                      năng động, sáng tạo, để mọi người có thể phát huy hết khả
                      năng của mình.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Đối với xã hội:
                      </span>{" "}
                      Luôn tâm niệm kinh doanh lợi nhuận không phải là hàng đầu,
                      mà kinh doanh là tạo ra giá trị cho cộng đồng.
                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    <div className="counter-box">
                      <h2 style={{ fontSize: 24 }}>
                        Giá trị <br></br>cốt lõi
                      </h2>
                    </div>
                    <p>
                      <span style={{ fontWeight: "bold", marginTop: 10 }}>
                        BẢN LĨNH:
                      </span>{" "}
                      là vũ khí để vượt qua chính bản thân và dám đương đầu thử
                      thách tạo nên sự thành công.
                      <br />
                      <span style={{ fontWeight: "bold", marginTop: 10 }}>
                        SÁNG TẠO:{" "}
                      </span>{" "}
                      là lợi thế cạnh tranh.
                      <br />
                      <span style={{ fontWeight: "bold", marginTop: 10 }}>
                        TẬN TÂM:
                      </span>{" "}
                      là phương châm hành động.
                      <br />
                      <span style={{ fontWeight: "bold", marginTop: 10 }}>
                        ĐOÀN KẾT:
                      </span>{" "}
                      là sức mạnh.
                      <br />
                      <span style={{ fontWeight: "bold", marginTop: 10 }}>
                        CHÍNH TRỰC:
                      </span>{" "}
                      là sự trường tồn.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image-group mt-5 mt-lg-0 row">
                <div className="col-md-6 p-3">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/introducer/vanhoa-01.jpg"
                    }
                    alt="aboutIMG"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 p-6">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/introducer/vanhoa-02.jpg"
                    }
                    alt="aboutIMG"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 p-6">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/introducer/vanhoa-03.jpg"
                    }
                    alt="aboutIMG"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 p-3">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/introducer/vanhoa-04.jpg"
                    }
                    alt="aboutIMG"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="1MA_2I7MKOc"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutWrapperArea;
