import React from "react";
// import Pagination from "../../common/Pagination";
import PackageCard from "./PackageCard";

function PackageGridWrapper({ tours }) {
  return (
    <>
      {/* ===============  Package gird area start =============== */}
      <div className="package-wrapper pt-110">
        <div className="container">
          <div className="row g-4">
            {tours.map((item, idx) => (
              <div className="col-lg-4 col-md-6" key={idx}>
                <PackageCard tour={item} />
              </div>
            ))}
          </div>
          {/* <Pagination /> */}
        </div>
      </div>
      {/* ===============  Package gird area end =============== */}
    </>
  );
}

export default PackageGridWrapper;
