import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET_EVENTS, GET_TOURS } from "../../../api-client";
import PackageCard from "../packageGrid/PackageCard";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function PackageArea() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const [tours, setTours] = useState([]);
  const [events, setEvents] = useState([]);

  const getTours = () => {
    GET_TOURS()
      .then(({ data, status }) => {
        if (status === 200) {
          setTours(data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getEvents = () => {
    GET_EVENTS()
      .then(({ data, status }) => {
        if (status === 200) {
          setEvents(data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTours();
    getEvents();
  }, []);

  const eventsSlider = {
    slidesPerView: 1,
    speed: 2000,
    spaceBetween: 24,
    loop: true,
    roundLengths: true,
    centeredSlides: true,
    autoplay: {
      delay: 1500,
    },
    navigation: {
      nextEl: ".testi-next",
      prevEl: ".testi-prev",
    },
    breakpoints: {
      480: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <div className="package-area package-style-one pt-110">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="section-head-alpha">
                <h2>Các gói du lịch phổ biến</h2>
                <p>
                  Tự hào là nhà lữ hành chuyên nghiệp với kinh nghiệm hơn 10 năm
                  tổ chức các Tour du lịch trong & ngoài nước
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="package-btn text-lg-end">
                <Link
                  to={`${process.env.PUBLIC_URL}/package`}
                  onClick={scrollTop}
                  className="button-fill-primary"
                >
                  Xem tất cả
                </Link>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {tours.length > 0
              ? tours
                  .sort(() => Math.random() - 0.5)
                  .filter((_, idx) => idx < 6)
                  .map((tour, idx) => (
                    <div className="col-lg-4 col-md-6" key={idx}>
                      <PackageCard tour={tour} />
                    </div>
                  ))
              : null}
          </div>
        </div>
      </div>

      {events.length > 0
        ? events
            .filter((item) => item.attributes.show_to_desktop)
            .map((item, idx) => (
              <div className="package-area package-style-one pt-110" key={idx}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-8">
                      <div className="section-head-alpha">
                        <h2>{item.attributes.event_name}</h2>
                        <p>{item.attributes.description}</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="slider-arrows text-center d-lg-flex d-none justify-content-end mb-3">
                        <div
                          className="testi-prev custom-swiper-prev"
                          tabIndex={0}
                          role="button"
                          aria-label="Previous slide"
                        >
                          <i className="bi bi-chevron-left" />
                        </div>
                        <div
                          className="testi-next custom-swiper-next"
                          tabIndex={0}
                          role="button"
                          aria-label="Next slide"
                        >
                          <i className="bi bi-chevron-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 overflow-hidden">
                  <Swiper
                    {...eventsSlider}
                    className="swiper destination-slider-one"
                  >
                    <div className="swiper-wrapper">
                      {item.attributes.package_tours.data.length > 0
                        ? item.attributes.package_tours.data
                            .sort(() => Math.random() - 0.5)
                            .filter((_, idx) => idx < 6)
                            .map((tour, idx) => (
                              <SwiperSlide className="swiper-slide" key={idx}>
                                <PackageCard tour={tour} />
                              </SwiperSlide>
                            ))
                        : null}
                    </div>
                  </Swiper>
                </div>
              </div>
            ))
        : null}
    </div>
  );
}

export default PackageArea;
