import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { READ_DESTINATION } from "../../../api-client";
import Breadcrumb from "../../common/Breadcrumb";
import DestinationWrapper from "./DestinationWrapper";
// import Pagination from "../../common/Pagination";

function Destination() {
  const params = useParams();
  const [dest, setDest] = useState(null);

  const readLocation = () => {
    params?.id &&
      READ_DESTINATION(params.id)
        .then(({ data, status }) => {
          if (status === 200) {
            setDest(data.data.attributes);
          }
        })
        .catch((error) => console.log(error));
  };

  useEffect(() => {
    readLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <>
      <Breadcrumb name={`${dest?.name || "Điểm đến hấp dẫn"}`} />
      <DestinationWrapper locations={dest?.locations || []} />

      {/* <Pagination /> */}
    </>
  );
}

export default Destination;
